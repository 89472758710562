<template>
  <section v-if="categories">
    <div class="py-32 md:py-48 lg:py-88">
      <h2 class="px-16 text-center text-h-xl md:text-h-2xl font-semibold pb-16 md:pb-32">
        {{ categories.title }}
      </h2>
      <div class="flex justify-center px-16">
        <div class="flex overflow-auto gap-8 md:gap-16 lg:gap-32">
          <Link
            v-for="category of categoryList.slice(0, 5)"
            :key="category.id"
            :href="`/advisors/${category.url}`"
            class="no-underline text-black shrink-0"
          >
            <article
              class="py-16 lg:py-48 px-8 lg:px-24 rounded-8 md:rounded-12 flex-grow-1 w-101 md:w-120 lg:w-200"
              :class="colorSchemes[category.color]?.bg"
            >
              <div class="flex justify-center">
                <div class="pb-8 md:pb-20">
                  <suspense>
                    <se-img
                      v-if="category.icon"
                      :alt="category.name"
                      :src="`/static/images/category/${config.theme.name}/${category.icon}.svg`"
                      :is-static="true"
                      class="w-48 h-48 md:w-64 md:h-64 lg:w-88 lg:h-88"
                    />
                  </suspense>
                </div>
              </div>
              <h4 class="text-h-s md:text-h-m lg:text-h-xl text-center font-bold" :class="colorSchemes[category.color]?.text">
                {{ category.name }}
              </h4>
            </article>
          </Link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { theme } from '../../../../../../theme';
import { usersStore } from '../../../../../store/users';
import SeImg from '../../../../../shared/ui-kit/img/Img.vue';
import Link from '../../../../../components/Link.vue';
import config from '../../../../../../config';

const { categories } = theme.home;

const user = storeToRefs(usersStore());

const categoryList = computed(() => user.categories.value);

const colorSchemes: Record<string, {text: string, bg:string}> = {
  feature: {
    text: 'text-feature-120',
    bg: 'bg-feature-10',
  },
  love: {
    text: 'text-love-120',
    bg: 'bg-love-10',
  },
  warning: {
    text: 'text-warning-120',
    bg: 'bg-warning-10',
  },
  success: {
    text: 'text-success-100',
    bg: 'bg-success-10',
  },
  primary: {
    text: 'text-primary-140',
    bg: 'bg-primary-10',
  },
};

onMounted(() => {
});
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeCategories',
};
</script>
