<template>
  <section v-if="reasons">
    <div class="container py-32 md:py-56 lg:py-88">
      <h2 class="text-h-xl md:text-h-2xl font-semibold text-center pb-16 md:pb-32">
        {{ reasons.title }}
      </h2>
      <div class="flex gap-16 lg:gap-32 flex-wrap md:!flex-nowrap">
        <se-card v-for="(reason, ri) of reasons.reasons" :key="ri" class="flex-grow-1 flex md:flex-col px-24 py-24 lg:pb-32 w-full">
          <div class="rounded-full w-48 h-48 min-w-48 lg:w-72 lg:h-72 flex items-center justify-center" :class="colorSchemes[reason.colorScheme]?.bg">
            <se-icon :name="reason.icon" :size="32" :class="colorSchemes[reason.colorScheme]?.text" class="hidden lg:flex lg:visible" />
            <se-icon :name="reason.icon" :size="20" :class="colorSchemes[reason.colorScheme]?.text" class="flex lg:hidden" />
          </div>
          <div class="pl-24 md:pl-0 md:pt-16 lg:pt-32">
            <h6 class="text-h-l lg:text-h-xl font-bold pb-8 lg:pb-16">
              {{ reason.title }}
            </h6>
            <p class="text-body-s lg:text-b-xl md:font-medium">
              {{ reason.description }}
            </p>
          </div>
        </se-card>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import SeCard from '../../../../../shared/ui-kit/card/Card.vue';
import SeIcon from '../../../../../shared/ui-kit/icon/Icon.vue';
import { theme } from '../../../../../../theme';

const reasons = ref(theme.home.reasons);
const props = defineProps({
  totalSessions: {
    type: String,
    required: true,
  },
  totalReviews: {
    type: String,
    required: true,
  },
});

const colorSchemes: Record<string, {text:string, bg: string}> = {
  warning: {
    text: 'text-warning-120',
    bg: 'bg-warning-10',
  },
  success: {
    text: 'text-success-100',
    bg: 'bg-success-10',
  },
  feature: {
    text: 'text-feature-120',
    bg: 'bg-feature-10',
  },
};

watch(() => props.totalSessions, (newVal) => {
  if (reasons.value) reasons.value.reasons[0].title = newVal;
});

watch(() => props.totalReviews, (newVal) => {
  if (reasons.value) reasons.value.reasons[2].title = newVal;
});
onMounted(() => {
  if (reasons.value) reasons.value.reasons[0].title = props.totalSessions;
  if (reasons.value) reasons.value.reasons[2].title = props.totalReviews;
});
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeReasons',
};
</script>
